
const messages = {
  topBar: {
    issuesHistory: 'ইস্যু ইতিহাস',
    projectManagement: 'প্রকল্প পরিচালনা',
    typeToSearch: 'অনুসন্ধান করুন...',
    findPages: 'পৃষ্ঠাগুলি সন্ধান করুন...',
    actions: 'ক্রিয়া',
    status: 'স্থিতি',
    profileMenu: {
      hello: 'হ্যালো',
      billingPlan: 'বিলিং পরিকল্পনা',
      role: 'ভূমিকা',
      email: 'ইমেল',
      phone: 'ফোন',
      profile: 'জীবন বৃত্তান্ত সম্পাদনা',
      editPassword: 'পাসওয়ার্ড সম্পাদনা করুন',
      logout: 'প্রস্থান',
    },
  },
}

export default {
  locale: 'bd-BD',
  messages,
}
