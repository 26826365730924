var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-sider',{class:( _obj = {}, _obj[_vm.$style.menu] = true, _obj[_vm.$style.white] = _vm.settings.menuColor === 'white', _obj[_vm.$style.gray] = _vm.settings.menuColor === 'gray', _obj[_vm.$style.dark] = _vm.settings.menuColor === 'dark', _obj[_vm.$style.unfixed] = _vm.settings.isMenuUnfixed, _obj[_vm.$style.shadow] = _vm.settings.isMenuShadow, _obj ),attrs:{"width":_vm.settings.leftMenuWidth,"collapsible":_vm.settings.isMobileView ? false : true,"collapsed":_vm.settings.isMenuCollapsed && !_vm.settings.isMobileView},on:{"collapse":_vm.onCollapse}},[_c('div',{class:_vm.$style.menuOuter,style:({
        width: _vm.settings.isMenuCollapsed && !_vm.settings.isMobileView ? '80px' : _vm.settings.leftMenuWidth + 'px',
        height: _vm.settings.isMobileView || _vm.settings.isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
      })},[_c('div',{class:_vm.$style.logoContainer},[_c('div',{class:_vm.$style.logo},[_c('img',{staticClass:"daraz-logo-menu-left mr-2",attrs:{"src":"/resources/images/Daraz_Lockup_Horizontal_Orange_RGB.png","alt":"Daraz BD"}})])]),_c('div',{staticStyle:{"padding-left":"1em"}},[_c('div',{staticClass:"ant-menu-item-group-title",staticStyle:{"padding-left":"16px","padding-bottom":"2px !important"}},[_vm._v(_vm._s(_vm.user.role || '—'))]),_c('div',{staticClass:"ant-menu-item-group-title",staticStyle:{"padding-left":"16px","padding-top":"2px !important"}},[(_vm.user.role.includes('hub_in_charge'))?_c('a-spin',{attrs:{"spinning":_vm.spinning,"delay":_vm.delayTime}},[_vm._v(" "+_vm._s(_vm.hub.hub ? _vm.hub.hub.name : '')+"[ "+_vm._s(_vm.hub.hub ? _vm.hub.hub.hub_zone ? _vm.hub.hub.hub_zone.hub_zone_desc: '' : '')+"] ")]):_vm._e()],1)]),_c('vue-custom-scrollbar',{style:({
          height: _vm.settings.isMobileView || _vm.settings.isMenuUnfixed ? 'calc(100vh - 140px)' : 'calc(100vh - 190px)',
        })},[_c('a-menu',{class:_vm.$style.navigation,attrs:{"forceSubMenuRender":"","inlineCollapsed":_vm.settings.isMobileView ? false : _vm.settings.isMenuCollapsed,"mode":'inline',"selectedKeys":_vm.selectedKeys,"openKeys":_vm.openKeys,"inlineIndent":15},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event},"click":_vm.handleClick,"openChange":_vm.handleOpenChange}},[_vm._l((_vm.menuData),function(item,index){return [(!item.roles || item.roles.filter(function (element) { return _vm.user.role.includes(element); }).length > 0)?[(item.category)?_c('a-menu-item-group',{key:index},[_c('template',{slot:"title"},[_vm._v(_vm._s(item.title))])],2):_vm._e(),(!item.children && !item.category)?_c('item',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e(),(item.children)?_c('sub-menu',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e()]:_vm._e()]})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }