<template>
  <div :class="{
    'cui__layout__grayBackground': settings.isGrayBackground,
  }">
    <a-layout
      :class="{
      'cui__layout__contentMaxWidth': settings.isContentMaxWidth,
      'cui__layout__appMaxWidth': settings.isAppMaxWidth,
      'cui__layout__grayBackground': settings.isGrayBackground,
      'cui__layout__squaredBorders': settings.isSquaredBorders,
      'cui__layout__cardsShadow': settings.isCardShadow,
      'cui__layout__borderless': settings.isBorderless,
    }"
    >
      <a-layout>
        <a-layout-content style="height: 100%;  position: relative">
          <div class="cui__utils__content">
            <transition :name="settings.routerAnimation" mode="out-in">
              <router-view />
            </transition>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RoomBooking',
  components: { },
  computed: mapState(['settings']),
  data: function () {
    return { }
  },
  mounted() { },
  methods: { },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
