import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'
import { Empty } from 'ant-design-vue'
import $ from 'jquery'

Vue.use(Vuex)

export default {
  state: {
    vendors: [],
    vendor: {},
    loading: false,
    loader: false,
    flag: false,
    btnEdit: false,
    show: false,
    simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  },
  mutations: {
    SET_VENDORS(state, vendors) {
      state.vendors = vendors
    },
    SET_VENDOR(state, vendor) {
      state.vendor = vendor
    },
    DELETE_VENDOR: (state, id) => {
      var index = state.vendors.findIndex(vendor => vendor.id === id)
      state.vendors.splice(index, 1)
    },
  },
  actions: {
    getVendors({ commit, state }) {
      state.loading = true
      state.flag = true
      state.loader = true
      apiClient.get('api/vendors')
        .then(response => {
          state.loading = false
          state.flag = true
          state.loader = false
          commit('SET_VENDORS', response.data.vendors)
          setTimeout(function() {
            $('#vendor_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    async edit({ commit, state }, id) {
      state.vendor = {}
      state.btnEdit = true
      await apiClient.get('api/vendors/' + id + '/edit').then(response => {
        if (!response.data.error) {
          commit('SET_VENDOR', response.data.vendor)
        }
      })
    },
    onDelete({ commit }, vendorId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/vendors/' + vendorId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#vendor_list').DataTable().destroy()
              commit('DELETE_VENDOR', vendorId)
              // this.getVendors()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm({ state }) {
      state.btnEdit = false
      state.vendor = {}
    },
  },
  getters: {
    vendors: state => state.vendors,
    vendor: state => state.vendor,
    loading: state => state.loading,
    loader: state => state.loader,
    flag: state => state.flag,
    btnEdit: state => state.btnEdit,
    show: state => state.show,
    simpleImage: state => state.simpleImage,
  },
}
