<template>
  <div :class="$style.topbar">
    <div class="mr-4"></div>
    <div class="mr-auto"></div>
    <div class="mr-4 d-none d-md-block"></div>
    <div class="mb-0 mr-auto d-xl-block d-none"></div>
    <div class="mr-4 d-none d-sm-block">
<!--      <cui-language-switcher />-->
    </div>
    <div class="mr-4 d-none d-sm-block">
      <cui-actions />
    </div>
    <div>
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
// import CuiLanguageSwitcher from './LanguageSwitcher'
import CuiActions from './Actions'
import CuiUserMenu from './UserMenu'

export default {
  components: {
    // CuiLanguageSwitcher,
    CuiUserMenu,
    CuiActions,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
