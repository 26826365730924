module.exports = function years() {
  const years = {
    2020: '2020',
    2021: '2021',
    2022: '2022',
    2023: '2023',
    2025: '2025',
    2026: '2026',
    2027: '2027',
  }
  return years
}
