import axios from 'axios'
import store from 'store'
import router from '../../router'
// import { notification } from 'ant-design-vue'

const apiClient = axios.create({
  // baseURL: '/api',
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

// apiClient.interceptors.response.use(undefined, error => {
//   // Errors handling
//   const { response } = error
//   const { data } = response
//   if (data) {
//     notification.warning({
//       message: data,
//     })
//   }
// })

apiClient.interceptors.response.use(response => response, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data.code === 403) {
    router.push({ path: '*', redirect: 'auth/404', hidden: true })
  }
  return Promise.reject(error)
})

export default apiClient
