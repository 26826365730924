var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'cui__layout__grayBackground': _vm.settings.isGrayBackground,
}},[_c('a-layout',{class:{
    'cui__layout__contentMaxWidth': _vm.settings.isContentMaxWidth,
    'cui__layout__appMaxWidth': _vm.settings.isAppMaxWidth,
    'cui__layout__grayBackground': _vm.settings.isGrayBackground,
    'cui__layout__squaredBorders': _vm.settings.isSquaredBorders,
    'cui__layout__cardsShadow': _vm.settings.isCardShadow,
    'cui__layout__borderless': _vm.settings.isBorderless,
  }},[_c('a-layout',[_c('a-layout-content',{staticStyle:{"height":"100%","position":"relative"}},[_c('div',{staticClass:"cui__utils__content"},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }