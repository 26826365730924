<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-badge :count="notifications.length > 0 ? notifications.length : 0">
        <i class="fe fe-bell" :class="$style.icon" />
      </a-badge>
    </div>
    <div slot="overlay">
      <div class="card cui__utils__shadow width-350">
        <div class="card-body pb-2">
          <div v-if="notifications.length > 0" class="row">
            <div class="col-md-12">
              <a class="pull-right" href="javascript: void(0);" @click="markAsRead()">
                Mark all as read
              </a>
            </div>
          </div>
          <hr>
          <ul class="list-unstyled">
            <a-spin :spinning="spinning" :delay="delayTime">
              <template v-if="notifications.length > 0">
                <li :class="$style.item" v-for="(notification, index) in notifications" :key="index">
                  <span v-if="notification.data['requisition_id']">
                    <router-link :to="{ name: 'warehouseHubRequisitionView', params: { requisition_id: notification.data['requisition_id'] } }" v-on:click.native="markAsRead(notification.id)" :class="$style.itemLink">
                      <div :class="$style.itemMeta" class="font-weight-bold text-danger">
                        {{ customDate(notification.data['created_at']) }}
                      </div>
                      <div class="mr-3">
                        <div>{{notification.data['subject']}}</div>
                      </div>
                      <div :class="$style.itemAction">
                        <a href="javascript: void(0);" title="Mark as read" @click="markAsRead(notification.id)">
                          <i class="fa fa-check-circle"></i>
                        </a>
                      </div>
                    </router-link>
                  </span>
                  <span v-if="notification.data['purchase_request_id']">
                    <router-link :to="{ name: 'purchaseRequestDetail', params: { purchase_request_id: notification.data['purchase_request_id'] } }"  :class="$style.itemLink">
                      <div :class="$style.itemMeta" class="font-weight-bold text-danger">
                        {{ customDate(notification.data['created_at']) }}
                      </div>
                      <div class="mr-3">
                        <div>{{notification.data['subject']}}</div>
                      </div>
                      <div :class="$style.itemAction">
                        <a href="javascript: void(0);" title="Mark as read" @click="markAsRead(notification.id)">
                          <i class="fa fa-check-circle"></i>
                        </a>
                      </div>
                    </router-link>
                  </span>
                  <span v-if="notification.data['fuel_requisition_id']">
                    <router-link :to="{ name: 'hubFuelRequisitionView', params: { fuel_requisition_id: notification.data['fuel_requisition_id'] } }"  :class="$style.itemLink">
                      <div :class="$style.itemMeta" class="font-weight-bold text-danger">
                        {{ customDate(notification.data['created_at']) }}
                      </div>
                      <div class="mr-3">
                        <div>{{notification.data['subject']}}</div>
                      </div>
                      <div :class="$style.itemAction">
                        <a href="javascript: void(0);" title="Mark as read" @click="markAsRead(notification.id)">
                          <i class="fa fa-check-circle"></i>
                        </a>
                      </div>
                    </router-link>
                  </span>
                  <span v-if="notification.data['requested_transaction_id']">
                    <router-link :to="{ name: 'adminExpenseView', params: { requested_transaction_id: notification.data['requested_transaction_id'] } }"  :class="$style.itemLink">
                      <div :class="$style.itemMeta" class="font-weight-bold text-danger">
                        {{ customDate(notification.data['created_at']) }}
                      </div>
                      <div class="mr-3">
                        <div>{{notification.data['subject']}}</div>
                      </div>
                      <div :class="$style.itemAction">
                        <a href="javascript: void(0);" title="Mark as read" @click="markAsRead(notification.id)">
                          <i class="fa fa-check-circle"></i>
                        </a>
                      </div>
                    </router-link>
                  </span>
                  <span v-if="notification.data['purchase_request_id'] && notification.data['tag'] === 'free'">
                    <router-link :to="{ name: 'hubFreePendingProduct' }"  :class="$style.itemLink">
                      <div :class="$style.itemMeta" class="font-weight-bold text-danger">
                        {{ customDate(notification.data['created_at']) }}
                      </div>
                      <div class="mr-3">
                        <div>{{notification.data['subject']}}</div>
                      </div>
                      <div :class="$style.itemAction">
                        <a href="javascript: void(0);" title="Mark as read" @click="markAsRead(notification.id)">
                          <i class="fa fa-check-circle"></i>
                        </a>
                      </div>
                    </router-link>
                  </span>
                  <span v-if="notification.data['requested_transaction_id'] && notification.data['tag'] === 'hub'">
                    <router-link :to="{ name: 'hubExpenseView', params: { requested_transaction_id: notification.data['requested_transaction_id'] } }"  :class="$style.itemLink">
                      <div :class="$style.itemMeta" class="font-weight-bold text-danger">
                        {{ customDate(notification.data['created_at']) }}
                      </div>
                      <div class="mr-3">
                        <div>{{notification.data['subject']}}</div>
                      </div>
                      <div :class="$style.itemAction">
                        <a href="javascript: void(0);" title="Mark as read" @click="markAsRead(notification.id)">
                          <i class="fa fa-check-circle"></i>
                        </a>
                      </div>
                    </router-link>
                  </span>
                  <span v-if="notification.data['delivery_challan_id']">
                    <router-link :to="{ name: 'hubDeliveryChallanView', params: { delivery_challan_id: notification.data['delivery_challan_id'] } }"  :class="$style.itemLink">
                      <div :class="$style.itemMeta" class="font-weight-bold text-danger">
                        {{ customDate(notification.data['created_at']) }}
                      </div>
                      <div class="mr-3">
                        <div>{{notification.data['subject']}}</div>
                      </div>
                      <div :class="$style.itemAction">
                        <a href="javascript: void(0);" title="Mark as read" @click="markAsRead(notification.id)">
                          <i class="fa fa-check-circle"></i>
                        </a>
                      </div>
                    </router-link>
                  </span>
                  <span v-if="notification.data['admin_scenario_issue_id']">
                    <span v-if="user.role.includes('hub_in_charge')">
                      <router-link :to="{ name: 'adminScenarioHubIssueDetails', params: { admin_scenario_issue_id: notification.data['admin_scenario_issue_id'] } }"  :class="$style.itemLink">
                      <div :class="$style.itemMeta" class="font-weight-bold text-danger">
                        {{ customDate(notification.data['created_at']) }}
                      </div>
                      <div class="mr-3">
                        <div>{{notification.data['subject']}}</div>
                      </div>
                      <div :class="$style.itemAction">
                        <a href="javascript: void(0);" title="Mark as read" @click="markAsRead(notification.id)">
                          <i class="fa fa-check-circle"></i>
                        </a>
                      </div>
                    </router-link>
                    </span>
                    <span v-else>
                      <router-link :to="{ name: 'adminAdminScenarioIssueView', params: { admin_scenario_issue_id: notification.data['admin_scenario_issue_id'] } }"  :class="$style.itemLink">
                      <div :class="$style.itemMeta" class="font-weight-bold text-danger">
                        {{ customDate(notification.data['created_at']) }}
                      </div>
                      <div class="mr-3">
                        <div>{{notification.data['subject']}}</div>
                      </div>
                      <div :class="$style.itemAction">
                        <a href="javascript: void(0);" title="Mark as read" @click="markAsRead(notification.id)">
                          <i class="fa fa-check-circle"></i>
                        </a>
                      </div>
                    </router-link>
                    </span>
                  </span>
                </li>
              </template>
              <template v-else>
                <li :class="$style.item">
                  <div>No Notification Found</div>
                </li>
              </template>
            </a-spin>
          </ul>
        </div>
      </div>
    </div>
  </a-dropdown>
</template>

<script>
import apiClient from '@/services/axios'
import { mapGetters } from 'vuex'

import moment from 'moment'

export default {
  components: { },
  data: function () {
    return {
      spinning: false,
      delayTime: 500,
      count: 0,
      notifications: {},
      timer: '',
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  created() {
    window.Echo.channel('notifications').notification((notification) => {
      const getNotification = notification.notification
      const notifiableId = getNotification.notifiable_id
      if (notifiableId === this.user.id) {
        this.notifications.push(getNotification)
      }
    })
    this.timer = setInterval(this.getUserNotifications, 900000)
  },
  mounted() {
    this.getUserNotifications()
  },
  methods: {
    getUserNotifications() {
      this.spinning = true
      apiClient.get('api/notifications')
        .then(response => {
          this.spinning = false
          this.notifications = response.data.notifications
        })
        .catch(error => {
          console.log(error)
        })
    },
    customDate(date) {
      return moment(date).format('MM Do')
    },
    markAsRead(notificationId = null) {
      apiClient.post('api/mark-as-read', { notificationId: notificationId })
        .then(response => {
          this.getUserNotifications()
        })
        .catch(error => {
          console.log(error)
        })
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
@import './list-style.module.scss';
</style>
